// components/ChatModal.jsx
import React, { useState, useEffect, useRef } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  IconButton,
  TextField,
  Button,
  Typography,
  Paper,
  Snackbar,
  Alert,
} from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import Slide from '@mui/material/Slide'
import { TransitionProps } from '@mui/material/transitions'

const Transition = React.forwardRef(function Transition(
  props: TransitionProps & { children: React.ReactElement },
  ref: React.Ref<unknown>,
) {
  return <Slide direction="up" ref={ref} {...props} />
})

const ChatModal = ({ open, onClose }) => {
  const [messages, setMessages] = useState([
    { sender: 'bot', text: 'Hello! How can I assist you today?' },
  ])
  const [input, setInput] = useState('')
  const [summary, setSummary] = useState('')
  const [snackbar, setSnackbar] = useState<{
    open: boolean
    message: string
    severity: 'error' | 'warning' | 'info' | 'success'
  }>({
    open: false,
    message: '',
    severity: 'error',
  })
  const [summaryIndex, setSummaryIndex] = useState(0)
  const chatBoxRef = useRef(null)

  const updateSummary = async (messages: Array<object>) => {
    try {
      const res = await fetch('/api/ai/summary', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          messages: messages.slice(summaryIndex),
          summary,
        }),
      })
      const data = await res.json()

      if (res.ok) {
        setSummary(data.summary)
        setSummaryIndex(messages.length)
      } else {
        setSnackbar({
          open: true,
          message: data.error || 'Error updating summary.',
          severity: 'error',
        })
      }
    } catch (error) {
      console.error('Error updating summary:', error)
      setSnackbar({
        open: true,
        message: 'Something went wrong with updating summary',
        severity: 'error',
      })
    }
  }

  const handleSend = async () => {
    if (input.trim() === '') return

    const userMessage = { sender: 'user', text: input }
    setMessages((prev) => [...prev, userMessage])
    setInput('')

    setTimeout(() => scrollToBottom(), 100)

    // Fetch AI response
    const botResponse = await getAIResponse(input)
    setMessages((prev) => [...prev, { sender: 'bot', text: botResponse }])

    if (messages.length + 2 - summaryIndex > 5) {
      const summary = await updateSummary(messages)
    }
    // Scroll to bottom after bot response
    setTimeout(() => {
      scrollToBottom()
    }, 100)
  }

  const getAIResponse = async (query: string) => {
    try {
      const res = await fetch('/api/ai/message', {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          prompt: query,
          summary,
          messages,
        }),
      })

      const data = await res.json()

      if (res.ok) {
        return data.message
      } else {
        setSnackbar({
          open: true,
          message: data.error || 'Error processing your request.',
          severity: 'error',
        })
        return 'Sorry, there was an error processing your request.'
      }
    } catch (error) {
      console.error('Error fetching AI response:', error)
      setSnackbar({
        open: true,
        message: 'Something went wrong. Please try again.',
        severity: 'error',
      })
      return 'Sorry, something went wrong.'
    }
  }

  const handleKeyPress = (e: React.KeyboardEvent<HTMLDivElement>) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault()
      handleSend()
    }
  }

  const scrollToBottom = () => {
    if (chatBoxRef.current) {
      chatBoxRef.current.scrollTop = chatBoxRef.current.scrollHeight
    }
  }

  useEffect(() => {
    if (open) {
      scrollToBottom()
    }
  }, [open])

  return (
    <>
      <Dialog
        open={open}
        onClose={onClose}
        TransitionComponent={Transition}
        aria-labelledby="ai-chat-dialog"
        fullWidth
        maxWidth="sm"
      >
        <DialogTitle sx={{ m: 0, p: 2 }}>
          AI Chat
          <IconButton
            aria-label="close"
            onClick={onClose}
            sx={{
              position: 'absolute',
              right: 8,
              top: 8,
              color: (theme) => theme.palette.grey[500],
            }}
          >
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent
          dividers
          ref={chatBoxRef}
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 1,
            maxHeight: '60vh',
            overflowY: 'auto',
          }}
        >
          {messages.map((msg, index) => (
            <Paper
              key={index}
              elevation={1}
              sx={{
                alignSelf: msg.sender === 'bot' ? 'flex-start' : 'flex-end',
                backgroundColor:
                  msg.sender === 'bot' ? 'grey.900' : 'primary.main',
                color: msg.sender === 'bot' ? 'text.primary' : 'common.white',
                padding: 1.5,
                borderRadius: 2,
                maxWidth: '80%',
              }}
            >
              <Typography variant="body1">{msg.text}</Typography>
            </Paper>
          ))}
        </DialogContent>
        <DialogActions sx={{ p: 2, borderTop: '1px solid #e0e0e0' }}>
          <TextField
            variant="outlined"
            placeholder="Type your message..."
            value={input}
            onChange={(e) => setInput(e.target.value)}
            onKeyPress={handleKeyPress}
            fullWidth
            multiline
            maxRows={4}
            aria-label="Type your message"
          />
          <Button
            variant="contained"
            color="primary"
            onClick={handleSend}
            disabled={input.trim() === ''}
            aria-label="Send message"
          >
            Send
          </Button>
        </DialogActions>
      </Dialog>

      {/* Snackbar for error notifications */}
      <Snackbar
        open={snackbar.open}
        autoHideDuration={6000}
        onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
        anchorOrigin={{ vertical: 'bottom', horizontal: 'center' }}
      >
        <Alert
          onClose={() => setSnackbar((prev) => ({ ...prev, open: false }))}
          severity={snackbar.severity}
          sx={{ width: '100%' }}
        >
          {snackbar.message}
        </Alert>
      </Snackbar>
    </>
  )
}

export default ChatModal
