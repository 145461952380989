// components/FloatingChatButton.jsx
import { useState } from 'react'
import { Fab, Tooltip } from '@mui/material'
import ChatIcon from '@mui/icons-material/Chat'
import ChatModal from './ChatModal'

const FloatingChatButton = () => {
  const [isChatOpen, setIsChatOpen] = useState(false)

  const handleToggleChat = () => {
    setIsChatOpen((prev) => !prev)
  }

  return (
    <>
      <Tooltip title="Open Chat" aria-label="open chat">
        <Fab
          color="primary"
          onClick={handleToggleChat}
          aria-haspopup="dialog"
          sx={{
            position: 'fixed',
            bottom: 16,
            right: 16,
            zIndex: (theme) => theme.zIndex.tooltip + 1, // Ensure it's above other elements
          }}
        >
          <ChatIcon />
        </Fab>
      </Tooltip>
      <ChatModal open={isChatOpen} onClose={handleToggleChat} />
    </>
  )
}

export default FloatingChatButton
